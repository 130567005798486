import React from "react"
import { Link } from "gatsby"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import * as PropTypes from "prop-types"
import linkIcon from "../../images/link.svg"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const RichText = ({ paragraph }) => {
  // utility to create title-slugify(based slugs for Contentful impo)rt
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
  }

  return renderRichText(paragraph, {
    renderMark: {
      [MARKS.ITALIC]: text => <span className="highlight">{text}</span>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) =>
        /^\//.test(node.data.uri) ? (
          <Link to={node.data.uri}>{children}</Link>
        ) : (
          <a href={node.data.uri}>{children}</a>
        ),
      [BLOCKS.HEADING_2]: node => (
        <h2 id={slugify(node.content[0].value)}>
          <a
            title="Link to this section"
            href={`#${slugify(node.content[0].value)}`}
          >
            <img src={linkIcon} alt="" />
          </a>
          {node.content[0].value}{" "}
        </h2>
      ),
      [BLOCKS.HEADING_3]: node => (
        <h3 id={slugify(node.content[0].value)}>
          <a
            title="Link to this section"
            href={`#${slugify(node.content[0].value)}`}
          >
            <img src={linkIcon} alt="" />
          </a>
          {node.content[0].value}{" "}
        </h3>
      ),
      [BLOCKS.HR]: node => <hr />,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (node.data.target.__typename === "ContentfulSection") {
          let linkPath = `/${node.data.target.slug}/`
          return <Link to={linkPath}>{children[0]}</Link>
        } else if (node.data.target.__typename === "ContentfulPage") {
          let linkPath
          node.data.target.section.slug === "/" // handle root pages
            ? (linkPath = `/${node.data.target.slug}/`)
            : (linkPath = `/${node.data.target.section.slug}/${node.data.target.slug}/`)

          return <Link to={linkPath}>{children[0]}</Link>
        }
      },
    },

    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  })
}

RichText.propTypes = {
  paragraph: PropTypes.object.isRequired,
}

export default RichText
